import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

interface Props {}
export const ProfileImage: React.FC<Props> = (): JSX.Element => {
  return (
    <StaticQuery
      query={query}
      render={(data: any) => {
        return (
          <Img
            sizes={data.file.childImageSharp.sizes}
            objectFit='cover'
            objectPosition='50% 50%'
            alt='profile image'
          />
        )
      }}
    />
  )
}

const query = graphql`
  query {
    file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 100, maxHeight: 100) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`
