import * as React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '@/hooks/use-site-metadata'
import useAnyImage from '@/hooks/use-any-image'

interface Props {
  title: string
  description: string
}

const SEO: React.FC<Props> = (props) => {
  const metadata = useSiteMetadata()!
  const icon = useAnyImage('site_icon.png')

  const {
    siteTitle,
    siteDescription: defaultDescription,
    siteLanguage,
    siteUrl,
    author
  } = metadata

  const seo = {
    title: props.title || siteTitle,
    description: props.description || defaultDescription,
    image: `${siteUrl}${icon?.src}`
  }

  return (
    <Helmet
      title={seo.title}
      defaultTitle={siteTitle}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang={siteLanguage} />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0, viewport-fit=cover, shrink-to-fit=no'
      />
      <meta name='description' content={seo.description} />
      <meta name='image' content={seo.image} />

      <meta property='og:type' content='website' />
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:image' content={seo.image} />
      <meta property='og:image:alt' content={seo.description} />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.image} />
      <meta name='twitter:image:alt' content={seo.description} />
      <meta name='twitter:creator' content={author} />

      {/* Search Console */}
      <meta
        name='google-site-verification'
        content='jWk9mnFjnjoZWxyCERCQ0PIWJUyNb6vVBkiJCVs98m0'
      />
    </Helmet>
  )
}

export default SEO
