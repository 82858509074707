import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { DeepReadonly } from 'utility-types'

type Props = DeepReadonly<{
  images: {
    edges: Array<{
      node: {
        relativePath: string
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }>
  }
}>

/**
 * use any image in src/assets/images
 */
export default (filename: string) => {
  const data = useStaticQuery<Props>(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find((edge) => {
    return edge.node.relativePath.includes(filename)
  })

  return image?.node.childImageSharp?.fluid
}
