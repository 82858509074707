import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

interface Props {}
const Footer: React.FC<Props> = (): JSX.Element => {
  return (
    <StyledComponent>
      <ul className='footer__link'>
        <li>
          <Link to='/request_job'>ご依頼・お問い合わせ</Link>
        </li>
        <li>
          <Link to='/privacy'>プライバシーポリシー</Link>
        </li>
      </ul>
      <div className='footer__text'>©︎ taikishiino. All Rights Reserved</div>
    </StyledComponent>
  )
}
export default Footer

const StyledComponent = styled.div`
  background: rgb(88, 99, 101);
  text-align: center;
  margin-top: 80px;
  padding: 24px 0;

  .footer__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      font-size: 14px;
      color: #fff;
      text-decoration: none;
      margin: 0 8px;
    }
  }
  .footer__text {
    font-size: 12px;
    color: #fff;
    margin-top: 12px;
  }
`
