import { graphql, useStaticQuery } from 'gatsby'
import { DeepPartial, DeepReadonly } from 'utility-types'

type Props = DeepReadonly<{
  site: DeepPartial<{
    siteMetadata: {
      siteTitle: string
      siteDescription: string
      siteUrl: string
      siteLanguage: string
      author: string
      social: {
        twitter: string
        github: string
      }
    }
  }>
}>

/**
 * ex. const {siteTitle, siteUrl} = useSiteMetadata();
 */
export default () => {
  const data = useStaticQuery<Props>(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteDescription
          siteUrl
          siteLanguage
          author
          social {
            twitter
            github
          }
        }
      }
    }
  `)

  return data.site.siteMetadata
}
