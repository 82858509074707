import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setSession } from '@/store/modules/session'

const IconLoading = require('@/images/icon_loading1.jpg')

interface Props {}
const Loading: React.FC<Props> = (): JSX.Element => {
  const dispatch = useDispatch()

  useEffect(() => {
    const images = document.getElementsByTagName('img')
    const loadingBar: any = document.getElementById('loading_bar')
    const loadingArea: any = document.getElementById('loading')
    const percent: any = document.getElementById('percent-text') // パーセントのテキスト部分

    let count = 0
    let current = 0
    let intervalCount = 0

    // 画像の読み込み
    const onLoadImage = setInterval(function () {
      const img = new Image() // 画像の作成
      // 画像読み込み完了したとき
      img.onload = function () {
        count += 1
        current = Math.floor((count / images.length) * 100)
        percent.innerHTML = current
        loadingBar.style.width = `${current}%`
      }
      img.src = images[intervalCount].src // 画像にsrcを指定して読み込み開始

      intervalCount++
      if (intervalCount >= images.length) {
        clearInterval(onLoadImage)
      }
    }, 100) // 0.1sごとにループ

    // ローディング処理
    const nowLoading = setInterval(function () {
      // 全て読み込んだ時
      if (current >= 100) {
        // ローディング要素の非表示
        loadingArea.classList.add('loadingNone')
        // ローディングの終了
        clearInterval(nowLoading)
        // session済みにステートを変更
        dispatch(setSession())
      }
    }, 1000)
  }, [dispatch])

  return (
    <StyledComponent>
      <div id='loading'>
        <div className='loading_barWrap'>
          <p className='text'>
            読み込み中 <span id='percent-text' /> %
          </p>
          <div className='loading-bar-items'>
            <div className='loading-bar-flex'>
              <div id='loading_bar' />
              <div className='loading-icon'>
                <img src={IconLoading} alt='アイコン' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledComponent>
  )
}
export default Loading

const StyledComponent = styled.div`
  #loading {
    width: 100vw;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .loading_barWrap {
      width: 600px;
      height: calc(1rem + 5px);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      color: #fff;
      .loading-bar-items {
        position: relative;
        width: 100%;
        .icon_goal {
          position: absolute;
          right: 0;
          top: -100px;
          width: 120px;
        }
        .loading-bar-flex {
          display: flex;
          align-items: center;
          border: 1px solid #000;
          border-left: none;
          border-right: none;
          height: 100%;

          .loading-icon {
            display: flex;
            width: 30px;
            height: 30px;
            img {
              width: 100%;
            }
          }
        }
      }
      #loading_bar {
        border-bottom: 1px dashed #004400;
        height: 18px;
        transition-duration: 0.5s;
      }

      .text {
        color: #000;
        margin: 0;
        margin-bottom: 4px;
      }
    }
  }
  .loadingNone {
    display: none;
    animation: loadingAnime 1s forwards;
  }
  @keyframes loadingAnime {
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    #loading {
      .loading_barWrap {
        width: 80%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    #loading {
      .loading_barWrap {
        width: 90%;
      }
    }
  }
  @media screen and (max-width: 480px) {
  }
`
